import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  dropZoneField,
  checkboxField,
  colorPicker,
  textBlock,
} from '@/utils/fields.js'
import { stringAction } from '@/utils/actions'
import { required, hasDate, hasTime, nameLength } from '@/utils/validation.js'
import { v4 as uuidv4 } from 'uuid'
import router from '@/router'

export default {
  id: uuidv4(),
  path: 'appoint',
  name: 'Назначить',
  route: 'appoint',
  type: 'FormDefault',
  detail: true,
  alias: 'object',
  active: false,
  lists: [
    {
      alias: 'direction_id',
      filter: [],
    },
    {
      alias: 'mass_assign_directions',
      filter: [
        {
          required: true,
          field: 'direction',
          alias: 'ids',
          source: 'formData',
          type: 'array',
        },
      ],
    },
  ],
  fields: [
    selectField({
      label: 'Направление',
      name: 'direction_id',
      subtype: 'single',
      placeholder: '',
      class: [''],
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      // object
      position: {
        cols: 12,
        sm: 12,
      },
      dependence: [
        {
          type: 'api',
          module: 'selects/getListUpdate',
          field: 'object_id',
          url: 'get/pagination_list/assign_objects',
        },
        {
          init: true,
          type: 'custom',
          func: (ctx) => {
            ctx.formData.direction = ctx.tableComp?.proxy?.hash
          },
        },
      ],
      isShow: {
        value: false,
        conditions: [
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return !ctx.tableComp?.proxy?.hash
            },
          },
        ],
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    selectField({
      label: 'Направление',
      name: 'direction',
      alias: 'mass_assign_directions',
      subtype: 'single',
      placeholder: '',
      class: [''],
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      // object
      position: {
        cols: 12,
        sm: 12,
      },
      dependence: [
        {
          type: 'api',
          module: 'selects/getListUpdate',
          field: 'account_id',
          url: 'get/pagination_list/assign_accounts',
          filter: [
            {
              field: 'ids',
              alias: 'object_id',
            },
            {
              field: 'direction',
              alias: 'direction_id',
            },
          ],
        },
        {
          init: true,
          type: 'custom',
          func: (ctx) => {
            ctx.formData.ids = ctx.tableComp?.proxy?.selectedRowsList.map(
              (x) => x.row.id
            )
          },
        },
      ],
      isShow: {
        value: false,
        conditions: [
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return ctx.tableComp?.proxy?.hash
            },
          },
        ],
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    autocompleteField({
      label: 'Объект',
      name: 'object_id',
      alias: 'assign_objects',
      subtype: 'multiple',
      placeholder: '',
      class: [''],
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      page: 1,
      search: '',
      url: 'get/pagination_list/assign_objects',
      position: {
        cols: 12,
        sm: 12,
      },
      isShow: {
        value: false,
        conditions: [
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return !ctx.tableComp?.proxy?.hash
            },
          },
        ],
      },
      validations: { required },
      bootstrapClass: [''],
      filter: [
        {
          required: true,
          field: 'direction_id',
          value: '',
        },
      ],
      dependence: [
        {
          type: 'api',
          module: 'selects/getListUpdate',
          field: 'account_id',
          url: 'get/pagination_list/assign_accounts',
        },
      ],
    }),
    autocompleteField({
      label: 'Сотрудник',
      name: 'account_id',
      alias: 'assign_accounts',
      subtype: 'single',
      placeholder: '',
      class: [''],
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      page: 1,
      search: '',
      url: 'get/pagination_list/assign_accounts',
      // object
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
      filter: [
        {
          field: 'direction_id',
          value: '',
        },
        {
          field: 'direction',
          alias: 'direction_id',
          value: '',
        },
        {
          field: 'object_id',
          value: '',
        },
        {
          field: 'ids',
          alias: 'object_id',
          value: '',
        },
      ],
      dependence: [
        {
          type: 'default',
          fillField: ['permission_id'],
        },
        {
          type: 'computed',
          funcComputed: (context) => {
            if (context.formData.permission_id !== 9) {
              context.formData.with_target = false
            }
          },
        },
      ],
    }),
    textBlock({
      label: 'Создал',
      name: 'permission_id',
      placeholder: '',
      readonly: true,
      class: [''],
      position: {
        cols: 12,
        sm: 12,
      },
      isShow: {
        value: false,
        conditions: [
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return !ctx.tableComp?.proxy?.hash
            },
          },
        ],
      },
      bootstrapClass: [''],
      //validations: { required },
      //isShow: false,
    }),
    selectField({
      label: 'Фильтр для назначения множества',
      name: 'ids',
      subtype: 'multiple',
      placeholder: '',
      readonly: true,
      class: [''],
      position: {
        cols: 12,
        sm: 12,
      },
      isShow: false,
      bootstrapClass: [''],
      //validations: { required },
      //isShow: false,
    }),
    checkboxField({
      label: 'С назначением',
      name: 'with_target',
      value: false,
      placeholder: '',
      class: [''],
      position: {
        cols: 12,
        sm: 12,
      },
      isShow: {
        value: false,
        conditions: [
          { field: 'permission_id', value: [9] },
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return ctx.tableComp?.proxy?.hash
            },
          },
        ],
      },
      bootstrapClass: [''],
    }),
  ],
  actions: [
    stringAction({
      text: 'Закрыть',
      type: 'submit',
      color: 'textDefault',
      name: 'closePopup',
      action: 'closePopup',
      to: 'object',
      skipValidation: true,
    }),
    stringAction({
      text: 'Сохранить',
      type: 'submit',
      module: 'form/create',
      name: 'createForm',
      url: 'create/assign',
      action: 'createForm',
      color: 'primary',
      isHide: {
        value: false,
        type: 'every',
        condition: [
          {
            funcCondition: (ctx) => {
              return !ctx.formData.ids.length
            },
            type: false,
          },
        ],
      },
      handlingResponse: {
        1: {
          text: 'Объект успешно назначен',
          color: 'success',
        },
        2: {
          text: 'Ошибка сервера',
          color: 'error',
        },
        3: {
          text: 'Объект уже назначен',
          color: 'error',
        },
        4: {
          text: 'Недостаточно данных для обработки запроса',
          color: 'error',
        },
      },
    }),
    stringAction({
      text: 'Подтвердить',
      type: 'submit',
      action: 'func',
      color: 'primary',
      isHide: {
        value: false,
        type: 'every',
        condition: [
          {
            funcCondition: (ctx) => {
              return !!ctx.formData.ids.length
            },
            type: false,
          },
        ],
      },
      func: async (ctx) => {
        const data = await ctx.store.dispatch('form/create', {
          url: 'mass/object/assign',
          body: {
            data: {
              ids: ctx.formData.ids,
              direction_id: ctx.formData.direction,
            },
          },
        })
        if (data.code === 1) {
          ctx.tableComp.proxy.hash = data.result
          ctx.tableComp.proxy.localStorage = {
            account_id: ctx.formData.account_id,
            permission_id: ctx.formData.permission_id,
            with_target: ctx.formData.with_target,
            direction_id: ctx.formData.direction,
            action: 'assign',
          }
          router.replace({
            name: 'object-appoint-many',
          })
          return
        }
        if (data.code === 2) {
          ctx.store.commit('notifies/showMessage', {
            color: 'warning',
            content: 'Попробуйте еще раз...',
            timeout: 2000,
          })
        } else if (data.code === 3) {
          ctx.store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Что-то пошло не так...',
            timeout: 2000,
          })
        } else if (data.code === 4) {
          ctx.store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Нет доступа',
            timeout: 2000,
          })
        }
      },
    }),
  ],
}
