import _, { reverse } from 'lodash'
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue'
export default {
  name: 'MultiCell',
  props: {
    value: {
      type: String | Number,
      default: () => 1,
    },
    size: {
      type: Number,
      default: () => 20,
    },
  },
  setup(props, ctx) {
    const banks = [
      {
        text: 'СБЕРБАНК',
        value: 1,
      },
      {
        text: 'Почта Банк',
        value: 2,
      },
      {
        text: 'Пром Связь',
        value: 3,
      },
      {
        text: 'Альфабанк',
        value: 4,
      },
      {
        text: 'Тинькофф',
        value: 5,
      },
      {
        text: 'ВТБ',
        value: 7,
      },
      {
        text: '-НАЛИЧНЫЕ-',
        value: 11,
      },
      {
        text: 'УБРИР',
        value: 12,
      },
      {
        text: 'Открытие',
        value: 13,
      },
      {
        text: 'МТС Банк',
        value: 14,
      },
    ]

    const bankIndex = computed(() => {
      if (!isNaN(props.value)) {
        return props.value
      } else {
        return banks.findIndex((el) => el.text === props.value) + 1
      }
    })

    onMounted(() => {})
    return {
      banks,
      bankIndex,
    }
  },
}
