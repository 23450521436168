import Vue, {
  ref,
  computed,
  onUnmounted,
  reactive,
  onMounted,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  forceUpdate,
  getCurrentInstance,
} from 'vue'
import {
  onBeforeRouteUpdate,
  useRoute,
  useRouter,
} from 'vue-router/composables'

import FormDefault from '@/components/Form/default/index.vue'
import FormStage from '@/components/Form/stage/index.vue'
import FormDocuments from '@/components/Form/documents/default/index.vue'
import FormRates from '@/components/Form/rates/default/index.vue'
import FormExpenses from '@/components/Form/expenses/index.vue'
import TableFixed from '@/components/Table/fixed/index.vue'
import FormOutputCorrect from '@/components/Form/output/correct/index.vue'
// import TableDefault from '@/components/Table/default/index.vue'
import FrameView from '@/components/Task/frame-view/index.vue'
import _ from 'lodash'

//import { form, list } from '@/api/index.js'
import store from '@/store'
import useNavigation from '@/compositions/useNavigation'

export default {
  name: 'Table-Detail',
  components: {
    FormDefault,
    TableFixed,
    FormStage,
    FrameView,
    FormRates,
    FormExpenses,
    FormDocuments,
    FormOutputCorrect,
  },
  props: {
    currentPos: {
      type: Number,
      default: () => 2,
    },
    content: {
      type: Object,
      default: () => {},
    },
    detail: {
      type: Object,
      default: () => {},
    },
    formDataParent: {
      type: Object,
      default: () => {},
    },
    tableComp: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const route = useRoute()
    const router = useRouter()
    const { id } = route?.params
    const loading = ref(false)
    const { detail } = props
    const syncForm = ref({})
    const activeTab = ref(0)
    const directions = computed(() => store.state.user.direction_json)
    const permission = computed(() => store.state.user.permission_id)
    const mainData = ref({})
    const detailComp = getCurrentInstance()

    const checkIncludesPermissions = (el) => {
      if (!el.permissions) return true
      return el.permissions.includes(permission.value)
    }
    const checkIncludesDirections = (el) => {
      //return el.direction_id.includes(directions.value)
      if (!el.direction_id) return true
      else {
        return (
          el.type === !!_.intersection(el.direction_id, directions.value).length
        )
      }
    }
    const checkMainData = (el) => {
      //return el.direction_id.includes(directions.value)
      if (!el.mainData) return true
      else {
        if (el.mainData === 'direction_json') {
          return (
            el.type ===
            !!_.intersection(el.value, mainData.value.direction_json).length
          )
        } else {
          if (Array.isArray(el.value)) {
            return el.type === el.value.includes(mainData.value[el.mainData])
          } else {
            return el.type === (mainData.value[el.mainData] === el.value)
          }
        }
      }
    }
    const checkCustomFunction = (el) => {
      if (!el.funcCondition) return true
      else {
        const ctx = {
          mainData: mainData.value,
          store,
        }
        return el.funcCondition(ctx)
      }
    }
    const availableTabs = computed(() => {
      return detail.tabs.filter((item) => {
        return (
          (route.meta.mode && route.meta.mode.includes(item.path)) ||
          (!route.meta.mode && !item.path)
        )
      })
    })

    const propsContent = ref(props.content)

    const availableTabsAll = computed(() => {
      return availableTabs.value.filter((tab) => {
        if (!tab.isShow) return tab
        else {
          return tab.isShow.condition.every((el) => {
            return (
              checkCustomFunction(el) &&
              checkIncludesPermissions(el) &&
              checkIncludesDirections(el) &&
              checkMainData(el)
            )
          })
          // if ()
        }
      })
    })

    const setFormData = (formData) => {
      // mainData.value = Object.assign(mainData.value, formData)
      mainData.value = {
        ...mainData.value,
        ...formData,
      }
      const { calcDetailVisibility } = useNavigation({
        props,
        activeTab,
        availableTabsAll,
        currentPos: props.currentPos * 2,
      })
      calcDetailVisibility()
    }
    const changeTabRoute = (item) => {
      router.push({ params: { cardTab: item.route } })
    }
    const { decrimentSet, calcDetailVisibility } = useNavigation({
      props,
      activeTab,
      availableTabsAll,
      currentPos: props.currentPos * 2,
    })
    onMounted(() => {
      if (
        !detail.tabs.filter((x) => {
          return !!x.isShow?.condition[0].mainData
        }).length
      )
        calcDetailVisibility()
    })
    onBeforeRouteUpdate((to, from, next) => {
      next()
      calcDetailVisibility()
    })
    onBeforeUnmount(() => {
      decrimentSet(props?.detail?.requestId)
      if (detail?.clearStore) store.commit('clearFormStorage')
    })
    return {
      loading,
      syncForm,
      propsContent,
      detail,
      id,
      availableTabs,
      activeTab,
      availableTabsAll,
      setFormData,
      mainData,
      changeTabRoute,
      detailComp,
      // calcDetailVisibility,
    }
  },
}
