import _ from 'lodash'

import formTerritoriesAddEdit from './config/form-doljnost-add-edit'
import tableServiceX5 from './config/table-service-x5'

export const config = {
  title: 'Должности',
  selector: '#mainTable',
  type: 'TableDefault',
  options: {
    selecting: true,
    search: {
      //   function: searchInputing,
    },
    headerFixed: true,
    url: 'get/pagination/doljnost_x5',
    title: 'Должности',
    contextMenu: {
      actions: [
        {
          icon: '$IconDelete',
          label: 'Удалить',
          isShow: {
            condition: [
              {
                funcCondition: (context) => {
                  const object_type = context.store.state.user.object_types
                  const permission = context.store.state.user.permission_id
                  const res =
                    ([3, 4].includes(permission) && object_type.includes(1)) ||
                    [8, 17].includes(permission)
                  return res
                },
                type: true,
              },
            ],
          },
          action: {
            type: 'confirm',
            dialog: {
              text: 'Вы подтверждаете удаление должности?',
              function: async (context) => {
                await context.store.dispatch('form/delForm', {
                  url: `delete/doljnost`,
                  body: { data: { id: context.data.row.id } },
                })
              },
            },
          },
        },
      ],
    },
  },
  panel: {
    buttons: [
      {
        label: 'Обновить',
        class: ['v-table-button--custom'],
        url: '$IconEdit',
        // function: consolePanel,
        backgroundColor: '#ffffff',
      },
      {
        label: 'Добавить',
        class: ['v-table-button--custom'],
        type: 'changeUrl',
        url: '/doljnost/add',
        icon: 'mdi-plus',
        backgroundColor: '#fff',
        isShow: {
          condition: [
            {
              funcCondition: (context) => {
                const object_type = context.store.state.user.object_types
                const permission = context.store.state.user.permission_id
                const res =
                  ([2, 3, 4].includes(permission) && object_type.includes(1)) ||
                  [8, 17].includes(permission)
                return res
              },
              type: true,
            },
          ],
        },
      },
    ],
  },
  head: [
    {
      title: 'id',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: 'left',
      },
      sorts: [
        {
          type: 'text',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '150',
      alias: 'd.id',
      value: 'id',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'Название',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: 'left',
      },
      sorts: [
        {
          type: 'text',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '1000',
      alias: 'd.name',
      value: 'name',
      search: {
        field: '',
        isShow: true,
      },
    },
  ],
  data: {
    rows: [],
    totalRows: null,
    pageLength: 20,
    currentPage: 1,
    totalPages: null,
    footer: null,
  },
  detail: {
    type: 'popup', // String 'popup' or 'page'
    classes: [''], // List class
    width: '600px',
    method: 'get',
    alias: 'bank',
    url: '/get/form/',
    name: 'Должность - редактирование',
    bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
    tabs: [
      _.cloneDeep(formTerritoriesAddEdit),
      _.cloneDeep(formTerritoriesAddEdit),
      // tableServiceX5,
    ],
    activeTab: null,
  },
}

export default config
