import filters from './filters'
import { required, hasDate, hasTime } from '@/utils/validation.js'
import {
  stringField,
  selectField,
  autocompleteField,
  dateField,
  checkboxField,
  datetimeField,
  dropZoneField,
  textBlock,
} from '@/utils/fields.js'
import { stringAction } from '@/utils/actions'
import formMagnitRequestAddEdit from './config/form-magnit-request-add-edit'
import formMagnitRequestUpload from './config/form-magnit-request-upload'

function consoleText(row) {}

function consoleButton(row) {}

function consolePanel() {}

function searchInputing(field) {}

const config = {
  selector: '#mainTable',
  options: {
    selecting: false,
    search: {
      function: searchInputing,
    },
    headerFixed: true,
    //url: 'https://dummyjson.com/users',
    url: 'get/pagination/shop_request_magnit',
    title: 'This is an about page1',
  },
  panel: {
    buttons: [
      {
        label: 'Добавить',
        class: ['v-table-button--custom'],
        url: 'mdi-plus',
        function: consolePanel,
        backgroundColor: '#fff',
        type: 'addItem',
        isShow: {
          condition: [
            // {
            //   permissions: [4, 5],
            //   type: true,
            // },
            {
              funcCondition: (ctx) => {
                const { permission_id, object_types, direction_json } =
                  ctx.store.state.user
                return !!(
                  [4, 5].includes(permission_id) ||
                  (permission_id === 3 &&
                    direction_json.includes(2) &&
                    object_types.includes(2))
                )
              },
              type: true,
            },
          ],
        },
      },
      // {
      //   label: 'Скачать',
      //   class: ['v-table-button--custom'],
      //   function: consolePanel,
      //   backgroundColor: '#fff',
      // },
      // {
      //   label: 'Загрузить',
      //   class: ['v-table-button--custom'],
      //   // function: consolePanel,
      //   type: 'changeUrl',
      //   url: 'shop-request-magnit/upload',
      //   backgroundColor: '#fff',
      // },
    ],
  },
  head: [
    {
      title: 'id',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: 'left',
      },
      sorts: [
        {
          type: 'string',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      alias: 't.id',
      isShow: true,
      width: '40',
      value: 'id',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'Статус',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: 'left',
      },
      sorts: [
        {
          type: 'string',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      alias: 'ssrm.name',
      isShow: true,
      width: '100',
      value: 'status_name',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'Создана',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: 'left',
      },
      sorts: [
        {
          type: 'date',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '150',
      alias: 'srm.date_create',
      value: 'date_create',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'На дату',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: 'left',
      },
      sorts: [
        {
          type: 'date',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '150',
      alias: 'srm.date_request',
      value: 'date_request',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'В работе у',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: undefined,
      },
      sorts: [
        {
          type: 'text',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '150',
      value: 'account_name',
      alias: 'sa.name',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'Объект',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: undefined,
      },
      sorts: [
        {
          type: 'text',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '150',
      value: 'object_name',
      alias: 'o.name',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'Линейщик',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: undefined,
      },
      sorts: [
        {
          type: 'text',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '150',
      value: 'personal_name',
      alias: "CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic)",
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'Должность',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: undefined,
      },
      sorts: [
        {
          type: 'text',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '150',
      value: 'doljnost_name',
      alias: 'd.name',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'Часы',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: undefined,
      },
      sorts: [
        {
          type: 'text',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '150',
      value: 'hour',
      alias: 'srm.hour',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'Файл счета / чек',
      type: 'download',
      actionCondition: false,
      align: 'center',
      fixed: {
        value: false,
        position: 'left',
      },
      sorts: [
        {
          type: 'string',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      rows: [
        {
          type: 'Action',
          value: 'path_act',
          color: '',
          icon: {
            icon: 'mdi-download',
            color: 'info',
          },
          action: async (context) => {
            console.log('ctx', context)
            context.Vue.downloadFile(context.row.row.path_act)
          },
          funcCondition: (context) => {
            return !context.row.row.path_act
          },
        },
      ],
      alias: 'z.payment_schet',
      isShow: true,
      width: '40',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'Примечание',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: undefined,
      },
      sorts: [
        {
          type: 'text',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '150',
      value: 'note',
      alias: 'srm.note',
      search: {
        field: '',
        isShow: true,
      },
    },
  ],
  data: {
    rows: [],
    totalRows: null,
    pageLength: 20,
    currentPage: 1,
    totalPages: null,
    footer: null,
  },
  detail: {
    name: 'Заявка на Магнит',
    type: 'popup', // String 'popup' or 'page'
    classes: [''], // List class
    width: '700px',
    method: 'get',
    alias: 'shop_request_magnit',
    url: '/get/form/',
    bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
    tabs: [formMagnitRequestAddEdit, formMagnitRequestUpload],
    activeTab: null,
  },
  filters,
}

export default config
