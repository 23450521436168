import _, { reverse } from 'lodash'
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import moment from 'moment'
import banksCards from '@/components/Icons/banks'
export default {
  name: 'MultiCell',
  // {
  //   title: '',
  //   alias: '',
  //   width: '100',
  //   fixed: {
  //     value: true,
  //     isShow: false,
  //   },
  //   icon: {
  //     icon: '',
  //     color: '',
  //     tooltip: 'zxcxzc',
  //   },
  //   sort: {
  //     alias: 'zxczc',
  //   },
  //   search: {
  //     alias: 'zxczc',
  //   },
  //   rows: [
  //     {
  //       type: '',
  //       // (Text | Action | Tag | Date | CheckBox)
  //       value: '',
  //       // color: '',
  //       class: [''],
  //       align: 'left',
  //       formatting: (Money | Bank_Card | Date | DateTime)
  //       // alias: '',
  //       icon: {
  //         icon: '',
  //         color: '',
  //       },
  //       action: 'zxc',
  //     },
  //   ],
  // }
  props: {
    cell: {
      type: Object,
      default: () => {},
    },
    row: Object,
    tableContext: Object,
  },
  components: {
    banksCards: banksCards,
  },
  setup(props, ctx) {
    const banks = [
      {
        text: 'СБЕРБАНК',
        value: 1,
      },
      {
        text: 'Почта Банк',
        value: 2,
      },
      {
        text: 'Пром Связь',
        value: 3,
      },
      {
        text: 'Альфабанк',
        value: 4,
      },
      {
        text: 'Тинькофф',
        value: 5,
      },
      {
        text: 'ВТБ',
        value: 7,
      },
      {
        text: '-НАЛИЧНЫЕ-',
        value: 11,
      },
      {
        text: 'УБРИР',
        value: 12,
      },
      {
        text: 'Открытие',
        value: 13,
      },
      {
        text: 'МТС Банк',
        value: 14,
      },
    ]

    const emitClick = (entity) => {
      if (props.tableContext.config.type === 'Fixed') {
        if (entity) {
          ctx.emit('dblclick', entity, {
            ...props.cell,
            key: props.row.row[props.cell.routeParam],
          })
          return
        }
      } else {
        if (!entity) {
          ctx.emit('dblclick', props.row, props.cell)
        }
      }
    }
    const bankLogo = (val) => {
      return 'mdi-plus'
    }

    const convertData = (val) => {
      if (val.length > 10)
        return moment(val, 'YYYY-MM-DD hh:mm').format('DD.MM.YYYY hh:mm')
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    const moneyFormatter = (value) => {
      return Number(value).toLocaleString('ru-US', {
        style: 'currency',
        currency: 'RUB',
        compactDisplay: 'short',
      })
    }
    const bankCardFormatter = (value) => {
      return value.toString().replace(/\B(?=(\d{4})+(?!\d))/g, ' ')
    }

    onMounted(() => {
      // console.log(props.cell)
      if (props.cell.rows)
        props.cell.rows.forEach((x) => {
          if (!['Text', 'Action', 'Tag', 'Date', 'CheckBox'].includes(x.type))
            x.type = 'Text'
        })
      props.cell.search.isShow ??= true
      props.cell.fixed.isShow ??= true
    })
    return {
      convertData,
      emitClick,
      moneyFormatter,
      bankCardFormatter,
      bankLogo,
    }
  },
}
