//import style from './style.css' assert { type: 'css' }
//document.adoptedStyleSheets.push(style)
import Vue, {
  onMounted,
  ref,
  computed,
  nextTick,
  watch,
  toRef,
  getCurrentInstance,
} from 'vue'
import {
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  useRoute,
  useRouter,
} from 'vue-router/composables'
import store from '@/store'
import axios from 'axios'

import useForm from '@/compositions/useForm.js'
import useRequest from '@/compositions/useRequest'

import { v4 as uuidv4 } from 'uuid'
import vContextmenu from '@/components/Contextmenu/default/index.vue'
import Sheet from '@/components/Sheet/default/index.vue'
import Popup from '@/components/Popup/index.vue'
import SwitchDefault from '@/components/Switch/default/index.vue'

//import vTableButton from '../button/index.js'
//import vButton from '../../button/index.js'
//import vInput from '../../input/default/index.js'
import vIconSort from '../../Icons/sort/index.vue'
import TableFilter from '../filter/index.vue'
import Detail from '../detail/index.vue'
import useMobile from '@/layouts/Adaptive/checkMob.js'
import useTable from '@/compositions/useTable.js'
import moment from 'moment/moment'
import TableActionPanel from '../actions-panel/index.vue'
import { initMassActionsPopup } from '@/utils/helpers'
import _ from 'lodash'
import TablePaginationPanel from '../pagination-panel/index.vue'
import { replace } from 'lodash'
import useNavigation from '@/compositions/useNavigation'
import MultiCell from '../cell/index.vue'
import ButtonsWrapper from '@/components/Table/actions-panel/buttons-wrapper/index.vue'
// import { post } from '@/api/axios'
// import { personal } from '@/pages/index.js'
//import { tableApi } from '@/api'

const table = {
  name: 'TableDefault',
  components: {
    //vTableButton,
    //vButton,
    //vInput,
    MultiCell,
    TableActionPanel,
    vIconSort,
    vContextmenu,
    Sheet,
    TableFilter,
    Popup,
    Detail,
    SwitchDefault,
    TablePaginationPanel,
    ButtonsWrapper,
  },
  props: {
    currentPos: {
      type: Number,
      default: () => 1,
    },
    options: {
      type: Object,
      default: () => {},
      require: true,
    },
    filtersConfig: {
      type: Object,
      default: () => {},
    },
    detail: {
      type: Object,
      default: () => {},
    },
    routeParam: {
      type: [String, Number],
      default: '',
    },
    mainData: {
      type: Object,
      default: () => {},
    },
    filter: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    update() {
      this.$forceUpdate()
    },
  },
  setup(props, ctx) {
    const { emit } = ctx
    const router = useRouter()
    const route = useRoute()
    const loading = ref(false)
    const headerOptions = ref([])
    const tablePosition = ref(null)
    const searchField = ref('')
    const isMobile = useMobile()
    const tableComp = getCurrentInstance()
    const { generalConfig } = useTable(props.options)
    const options = generalConfig()
    const proxyOptions = toRef(options, 'head')
    const detail = ref(options?.detail)
    const filters = ref(options?.filters)
    const panel = ref(options?.panel)
    const switchStatus = ref(false)
    const cells = ref(null)
    const mainTable = ref(null)
    const filtersCount = ref(0)
    const availibleTitlesForSortIcons = [
      'ФИО',
      'Объект',
      'Должность',
      'Менеджер',
    ]
    const padding = 32
    const lastSelected = ref({
      items: [],
      indexRow: 0,
    })
    const rowCount = [5, 10, 15, 20, 25, 30]
    const hash = ref(0)
    const localStorage = ref([])
    const contextmenu = ref({
      isShow: false,
      x: null,
      y: null,
      row: {},
      actions: {},
    })
    const filtersColumns = ref([...props.filter])
    const pagination = ref({
      totalRows: null,
      currentPage: 1,
      totalPages: null,
      countRows: options.data.pageLength,
    })
    const filter = ref({
      isShow: false,
    })
    const confirmDialog = ref({
      isShow: false,
      text: '',
      function: null,
      context: null,
      loading: false,
    })
    const customContent = ref({
      popup: {
        width: '400px',
        isShow: false,
      },
      component: null,
      data: null,
    })
    const paramsQuery = ref({
      currentPage: pagination.value.currentPage,
      searchGlobal: searchField.value,
      countRows: pagination.value.countRows,
      sorts: [],
      searchColumns: [],
    })
    const tableContext = ref({
      config: props.options,
      paramsQuery,
      store,
      Vue,
    })
    const popupForm = ref({
      isShow: false,
    })
    const currentDate = ref({
      monthArray: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      date: moment(new Date()).format('YYYY-MM'),
    })

    // TODO

    const selectedRowsList = ref([])

    const header = ref(null)

    const pinnedColumns = ref([])

    const allRowsSelector = ref(false)

    const calcPinnedMargin = (index) => {
      let width = availableActions()?.isShow ? 56 : 0
      for (let i = 0; i < index; i++) {
        width += pinnedColumns.value[i].width + 40
      }
      return width
    }

    const recalcColumnWidth = () => {
      proxyOptions.value.forEach((el, index) => {
        const blockIndex = [...header.value.children].findIndex((x) => {
          return x.innerText === el.title
        })
        el.width = header.value.children[blockIndex].clientWidth - padding
      })
    }

    const setColumnFixed = (title) => {
      let index = proxyOptions.value.findIndex((x) => {
        return x.title === title
      })
      let blockIndex = [...header.value.children].findIndex((x) => {
        return x.innerText === title
      })
      proxyOptions.value[index].width =
        header.value.children[blockIndex].clientWidth - padding
      proxyOptions.value[index].isFixed = !proxyOptions.value[index].isFixed
      proxyOptions.value.push(proxyOptions.value.pop())
      let pinnedIndex = pinnedColumns.value.findIndex((x) => {
        return x.title === title
      })
      if (pinnedIndex != -1) {
        pinnedColumns.value.splice(pinnedIndex, 1)
        return
      }
      pinnedColumns.value.push(proxyOptions.value[index])
    }

    const setColumnVisibility = (index, status) => {
      proxyOptions.value[index].isVisible = !proxyOptions.value[index].isVisible
      proxyOptions.value.push(proxyOptions.value.pop())

      let pinnedIndex = pinnedColumns.value.findIndex((x) => {
        return x.title === proxyOptions.value[index].title
      })
      if (pinnedIndex != -1) {
        pinnedColumns.value.splice(pinnedIndex, 1)
        recalcColumnWidth()
        return
      }
      pinnedColumns.value.push(proxyOptions.value[index])
      recalcColumnWidth()
    }

    const availableActions = () => {
      return props.options.bottomPanel
    }

    const panelAction = (data) => {
      console.log(data)
    }
    const wrapingRow = () => {
      const table = document.querySelector(options.selector)
      tablePosition.value = table.getBoundingClientRect().x
      options.head.forEach((headerEl) => {
        const headId = headerEl.value
        const { width, x } = headerOptions.value.find((el) => el.id === headId)
        if (
          x + width + tablePosition.value >= window.innerWidth &&
          headerEl.isShow
        ) {
          //
          emit('changeheadershow', { headerEl, value: false })
        } else if (
          x + width + tablePosition.value <= window.innerWidth &&
          !headerEl.isShow
        ) {
          emit('changeheadershow', { headerEl, value: true })
        }
      })
    }

    const { calcShowPopup, clearUnusedParams, isModalLast } = useNavigation({
      props,
      context: ctx,
      popupForm,
      currentPos: props.currentPos * 2,
    })

    const openChildRow = ($event, row) => {
      $event.stopPropagation()
      if (row.child.isShow) {
        row.child.isShow = false
      } else {
        row.child.isShow = true
      }
      if (contextmenu.value.isShow) {
        contextmenu.value.isShow = false
      }
    }
    const checkboxInput = (row, indexRow) => {
      let delta = Math.abs(lastSelected.value.indexRow - indexRow)
      if (indexRow > lastSelected.value.indexRow) {
        for (
          let i = lastSelected.value.indexRow;
          i < lastSelected.value.indexRow + delta;
          i++
        ) {
          if (!options.data.rows[i].row.selected) {
            options.data.rows[i].row.selected = true
            selectedRowsList.value.push(options.data.rows[i])
          } else {
            //options.data[i].row.selected = false
            //if (i === lastSelected.value.indexRow) options.data[i].row.selected = true
          }
        }
      } else {
        for (
          let i = lastSelected.value.indexRow;
          i > lastSelected.value.indexRow - delta;
          i--
        ) {
          if (!options.data.rows[i].row.selected) {
            options.data.rows[i].row.selected = true
            selectedRowsList.value.push(options.data.rows[i])
          } else {
            //options.data[i].row.selected = false
            //if (i === lastSelected.value.indexRow) options.data[i].row.selected = true
          }
        }
      }
    }

    const resetSelected = (status = false) => {
      if (status) {
        lastSelected.value.items.push(
          ...options.data.rows.filter((x) => {
            return !x.row.selected
          })
        )
        selectedRowsList.value.push(...lastSelected.value.items)
        options.data.rows.forEach((row) => {
          Vue.set(row.row, 'selected', true)
        })

        return
      }

      options.data.rows.forEach((row) => {
        Vue.set(row.row, 'selected', false)
      })
      allRowsSelector.value = false
      let idsList = []
      if (lastSelected.value.items.length) {
        idsList = lastSelected.value.items.map((x) => {
          return x.row.id
        })
      }
      const remainingItems = selectedRowsList.value.reduce((acc, el) => {
        if (!idsList.includes(el.row.id)) {
          acc.push(el)
        }
        return acc
      }, [])
      Vue.set(selectedRowsList, 'value', remainingItems)
      lastSelected.value.items = []
      lastSelected.value.indexRow = null
      switchStatus.value = false
      getItems()
    }

    const redrawRows = (isOnlySelected) => {
      switchStatus.value = isOnlySelected
      if (isOnlySelected) {
        options.data.totalPages = Math.ceil(
          selectedRowsList.value.length / paramsQuery.value.countRows
        )
        options.data.totalRows = selectedRowsList.value.length
        paramsQuery.value.currentPage = 1
        if (selectedRowsList.value.length <= paramsQuery.value.countRows) {
          options.data.rows = _.cloneDeep(selectedRowsList.value)
        } else {
          getItems()
        }
        return
      }
      getItems()
    }

    const saveLastSelected = (row, indexRow, value) => {
      // row.row.selected = !row.row.selected
      lastSelected.value.indexRow = indexRow
      if (
        options.data.rows.filter((x) => {
          x.row.selected
        }).length === options.data.rows.length
      ) {
        allRowsSelector.value = !allRowsSelector.value
      }
      if (value) {
        selectedRowsList.value.push(row)
        lastSelected.value.items.push(row)
      } else {
        const delIndex1 = selectedRowsList.value.findIndex((x) => {
          return x.row.id === row.row.id
        })
        const delIndex2 = lastSelected.value.items.findIndex((x) => {
          return x.row.id === row.row.id
        })
        lastSelected.value.items.splice(delIndex2, 1)
        selectedRowsList.value.splice(delIndex1, 1)
      }
      if (
        options.data.rows.filter((x) => {
          x.row.selected
        }).length === options.data.rows.length
      ) {
        allRowsSelector.value = !allRowsSelector.value
      }
      if (lastSelected.value.items.length) {
        allRowsSelector.value = true
      } else allRowsSelector.value = false
    }
    // Костыль для чистки инпута
    const clearField = () => {
      Vue.set(this, 'searchField', '')
    }
    function openSort(head) {
      proxyOptions.value.forEach((el, id) => {
        if (head.title === el.title) el.sorts[0].isShow = !el.sorts[0].isShow
      })
      this.update()
    }
    const sortRow = (head) => {
      const { value } = head
      const paramsCol = paramsQuery.value.sorts.find((el) => el.field === value)
      if (!paramsCol.value) {
        paramsCol.value = 'asc'
      } else if (paramsCol.value === 'asc') {
        paramsCol.value = 'desc'
      } else if (paramsCol.value === 'desc') {
        paramsCol.value = undefined
      }
      //if (head.sorts[0].value === undefined) {
      //  head.sorts[0].value = 'asc'
      //} else if (head.sorts[0].value === 'asc') {
      //  head.sorts[0].value = 'desc'
      //} else if (head.sorts[0].value === 'desc') {
      //  head.sorts[0].value = undefined
      //}
      //
    }
    const contextMenuRef = ref(null)
    const openContext = ($event, row) => {
      //return // eslint-disable-next-line
      if (!contextmenu.value.isShow) {
        $event.preventDefault()
      } else {
        return
      }
      const contextWidth = 200
      if (contextmenu.value.isShow) {
        setTimeout(() => {
          contextmenu.value.isShow = false
        }, 0)
      }
      //
      //
      // eslint-disable-next-line
      let direction = 'left' // eslint-disable-next-line
      let clientX = $event.clientX
      if ($event.clientX + contextWidth >= window.innerWidth) {
        direction = 'right'
        clientX = window.innerWidth - $event.clientX
      }
      // if (!contextMenuRef.value.availableContext.length) {
      //   return
      // }

      setTimeout(
        () => {
          contextmenu.value.isShow = true
          contextmenu.value.x = clientX
          contextmenu.value.y = $event.clientY
          ;(contextmenu.value.row = row),
            (contextmenu.value.direction = direction)
          contextmenu.value.actions = props.options.options.contextMenu
        },
        contextmenu.value.isShow ? 450 : 0
      )
    }
    const getWidth = (value) => {
      if (!value || !headerOptions.value.length) return
      const element = headerOptions.value.find((el) => el.id === value)
      return element.x
    }
    const setStickyCells = () => {
      headerOptions.value()
    }
    const getFixedStyle = (head) => {
      //
      const { width } = headerOptions.value.find((el) => el.id === head.value)
      //
      if (head.fixed.value && head.fixed.position) {
        //
        //if (head.fixed.position === 'right') {

        //}
        return {
          [head.fixed.position]:
            head.fixed.position === 'right'
              ? window.innerWidth - getWidth(head.value) - width * 2 + 'px'
              : getWidth(head.value) + 'px',
        }
      } else {
        return undefined
      }
    }
    const handlerContext = async ({ action, row }) => {
      if (action.action.type === 'changeUrl') {
        changeUrlPath(action.action.url + '/' + row.row[action.action.target])
      } else if (action.action.type === 'delete') {
        await deleteRow(row.row.id, action.action.alias)
      } else if (action.action.type === 'toRoute') {
        // await deleteRow(row.row.id, action.action.alias)
        router.push({
          name: action.action.routeName,
          params: {
            [action.action.routeTarget]: row.row[action.action.routeParam],
          },
        })
        popupForm.value.isShow = true
      } else if (action.action.type === 'confirm') {
        const context = {
          store,
          data: row,
          route,
        }
        confirmDialog.value.text = action.action.dialog.text
        confirmDialog.value.function = action.action.dialog.function
        confirmDialog.value.context = context
        confirmDialog.value.isShow = true
      } else {
        openRow(undefined, row)
      }
      contextmenu.value.isShow = false
    }
    const deleteRow = async (id, alias) => {
      await store.dispatch('table/get', {
        url: `set/data/${alias}`,
        data: {
          data: {
            del: 1,
            id,
          },
        },
      })
      getItems()
    }
    const openFilter = ($event) => {
      filter.value.isShow = true
    }
    const closeFilter = () => {
      filter.value.isShow = false
    }

    const triggerDialogFunction = async () => {
      confirmDialog.value.loading = true
      await confirmDialog.value.function(confirmDialog.value.context)
      confirmDialog.value.loading = false
      confirmDialog.value.isShow = false
      await getItems()
    }

    // Something like this should work:

    // function makeRequestCreator() {
    //     var call;
    //     return function(url) {
    //         if (call) {
    //             call.cancel();
    //         }
    //         call = axios.CancelToken.source();
    //         return axios.get(url, { cancelToken: call.token }).then((response) => {
    //
    //         }).catch(function(thrown) {
    //             if (axios.isCancel(thrown)) {
    //
    //             } else {
    //                 // handle error
    //             }
    //         });
    //     }
    // }
    // You then use it with

    //  var get = makeRequestCreator();
    //  get('someurl');

    //  Each new request will cancel the previous one

    let controller
    const countingDistances = () => {
      let left = 0
      let right = 0
      let all = 0
      cells?.value?.forEach((item, index) => {
        all += Number(props?.options?.head[index].width)
        if (props?.options?.head[index]?.fixed?.position === 'left') {
          item.style.left = `${left}px`
          left += Number(getComputedStyle(item).width.replace('px', ''))
        }
      })

      for (let index = props?.options?.head.length - 1; index >= 0; index--) {
        if (props?.options?.head[index]?.fixed?.position === 'right') {
          const item = cells.value.find(
            (x) => x.innerText === props?.options?.head[index]?.title
          )
          item.style.right = `${right}px`
          right += Number(getComputedStyle(item).width.replace('px', ''))
        }
      }
      mainTable.value.style.width = `${all}px`
    }

    const getItems = async () => {
      if (switchStatus.value) {
        options.data.rows = selectedRowsList.value.slice(
          (paramsQuery.value.currentPage - 1) * paramsQuery.value.countRows,
          paramsQuery.value.currentPage * paramsQuery.value.countRows
        )
        options.data.totalPages = Math.ceil(
          selectedRowsList.value.length / paramsQuery.value.countRows
        )
        return
      }

      if (switchStatus.value && selectedRowsList.value.length) {
        options.data.rows = selectedRowsList.value.slice(
          (paramsQuery.value.currentPage - 1) * paramsQuery.value.countRows,
          paramsQuery.value.currentPage * paramsQuery.value.countRows
        )
        options.data.totalPages = Math.ceil(
          selectedRowsList.value.length / paramsQuery.value.countRows
        )
        return
      }
      if (controller) controller.abort()
      controller = new AbortController()
      loading.value = true
      const { url } = props.options.options
      // Может быть без props. после merge cofilcts
      let sorts = []
      let searchColumns = []

      paramsQuery.value.sorts.forEach((el) => {
        if (!el.value) {
          return
        } else {
          sorts.push(el)
        }
      })
      paramsQuery.value.searchColumns.forEach((el) => {
        if (!el.value) {
          return
        } else {
          searchColumns.push(el)
        }
      })

      let by = undefined
      //
      if (props.routeParam || store?.state?.formStorage?.id) {
        by = [
          {
            field: props.options.options.urlDetail,
            value: +props.routeParam || store?.state?.formStorage?.id,
            // value: +props.routeParam,
            alias: props.options.options.alias,
          },
        ]
      }
      if (props.tableComp) {
        if (props.tableComp.proxy.hash) {
          filtersColumns.value.push({
            alias: 'hash',
            value: props.tableComp.proxy.hash,
            type: 'string',
          })
          if (props?.tableComp.proxy.localStorage?.action)
            filtersColumns.value.push({
              alias: 'mass_action',
              value: props.tableComp.proxy.localStorage.action,
              type: 'string',
            })
        }
      }
      const data = await store.dispatch('table/get', {
        url: url,
        data: {
          countRows: paramsQuery.value.countRows,
          currentPage: paramsQuery.value.currentPage,
          searchGlobal: paramsQuery.value.searchGlobal,
          period: props.options.panel.date ? currentDate.value.date : undefined,
          searchColumns,
          sorts,
          filter: filtersColumns.value,
          by,
        },
        params: {
          signal: controller.signal,
        },
      })
      options.data.rows = data.rows
      options.data.footer = data.footer
      if (options.data.rows?.length && options.data.rows) {
        options.data.totalPages = data.totalPage
        options.data.totalRows = data.total
        paramsQuery.value.currentPage = data.page
        lastSelected.value.items = []
        lastSelected.value.indexRow = 0
        // options.
        const structuredArray = []
        options.data.rows.forEach((row) => {
          if (options.options.selecting) {
            const items = []
            selectedRowsList.value.forEach((x) => {
              items.push(x.row.id)
            })
            Vue.set(row, 'selected', items.includes(row.id))
            if (items.includes(row.id)) lastSelected.value.items.push({ row })
          }
          structuredArray.push({
            row,
            child: {
              isShow: false,
              data: row,
            },
          })
        })
        options.data.rows = structuredArray
      } else {
        options.data.totalRows = 0
        paramsQuery.value.totalPages = options.data.totalPages
        paramsQuery.value.currentPage = 1
      }
      loading.value = false
      controller = undefined

      if (
        options.data.rows.filter((x) => {
          return x.row.selected
        }).length === options.data.rows.length
      ) {
        allRowsSelector.value = true
        return
      }
      allRowsSelector.value = false

      proxyOptions.value.forEach((x, index) => {
        x.isVisible = true
        Vue.set(proxyOptions.value, index, x)
        x.isFixed ??= false
        if (x.fixed.value) {
          x.isFixed = true
          pinnedColumns.value.push(x)
        }
      })
      if (lastSelected.value.items.length) {
        allRowsSelector.value = true
      }
      nextTick(() => {
        for (let i = 0; i < pinnedColumns.value.length; i++) {
          pinnedColumns.value[i].width =
            header.value.children[i + 1].clientWidth - 40
        }
      }, 0)
      // if (by && !data.total) {
      //   closePopupForm()
      //   return
      // }
      // const { calcRoutePosition } = useNavigation({
      //   props,
      //   context: ctx,
      //   popupForm,
      // })
      // calcRoutePosition()
      calcShowPopup()
    }
    const initHeadParams = () => {
      const { head } = options
      head.forEach((el) => {
        if (el.sorts?.length) {
          paramsQuery.value.sorts.push({
            field: el.value,
            value: el.sorts[0].default,
            alias: el.alias,
          })
        }
        if (el.search?.isShow) {
          paramsQuery.value.searchColumns.push({
            field: el.value,
            value: el.search.field,
            alias: el.alias,
          })
        }
      })
    }
    const watchScroll = () => {
      //const firstListItem = list.querySelector('.horizontal-scroll-container__list-item:first-child');
      //const lastHeadTable = header.options
      //const table = document.querySelector(options.selector)
    }
    const isElementXPercentInViewport = (element) => {
      /* eslint-disable */
      const { x } = element.getBoundingClientRect()
      /* eslint-disable */
      if (
        /* eslint-disable */
        element.offsetLeft + element.offsetWidth + x &&
        /* eslint-disable */
        element.offsetLeft < window.innerWidth
      ) {
        /* eslint-disable */
        return true
        /* eslint-disable */
      } else {
        /* eslint-disable */
        return false
      }
    }

    /** Рекурсивно проверет если внутри не пустые строки, не нулевые числа или true */
    const isValueEmpty = (value) =>{
      if (typeof value === 'object'){
        if (Array.isArray(value)){
          return value.reduce((acc, el) => 
          {
            if (typeof el === 'object') return acc || isValueEmpty(el)
            return acc || !!el, false
          }, false
          )
        }
        else {
          return Object.values(value).reduce((acc, el) => 
            {
              if (typeof el === 'object') return acc || isValueEmpty(el)
              return acc || !!el, false
            }
          )
        }
      }
      else {
        return !!value
      }
    }

    const saveFilter = async (filterData) => {
      filtersColumns.value = []
      filtersCount.value = Object.values(filterData).filter((el) => {
        return isValueEmpty(el)}).length
      filtersColumns.value = [...props.filter]
      filters.value?.fields?.forEach((el) => {
        if (!filterData[el.name]) {
          el.value = ''
          return
        }
        el.value = filterData[el.name]
        if (
          el.type === 'dateRange' &&
          filterData[el.name].every(
            (el) => el === null || el === undefined || el === ''
          )
        ) {
          return
        }
        if (Array.isArray(filterData[el.name]) && !filterData[el.name].length) {
          return
        }
        let type = el.typeFilter ? el.typeFilter : el.type
        type = type === 'autocomplete' ? 'select' : type
        type = type === 'dateRange' && 'date'
        type = type === 'datetime' ? 'date' : type

        const obj = {
          //field: el.name,
          value: filterData[el.name],
          alias: el.aliasFilter,
          // alias: el.name,
          type: el.typeFilter ? el.typeFilter : el.type,
          subtype: el.subtype,
        }
        filtersColumns.value.push(obj)
      })
      paramsQuery.value.currentPage = 1
      await getItems()
    }

    const doubleHandler = (
      $event,
      row,
      cell,
      indexRow = null,
      indexCell,
      activeIndexCells
    ) => {
      if (!options.detail || options.options.noTableAction) return
      if (props.options.options.doubleHandlerType === 'cell') {
        openCell($event, row, cell, indexRow, indexCell, activeIndexCells)
      } else {
        openRow($event, row, cell)
      }
    }

    const openRow = ($event, row) => {
      if (options.detail?.click) {
        if (options.detail.click.condition) {
          const condition = options.detail.click.condition.permissions.includes(
            store.state.user.permission_id
          )
          if (condition !== options.detail.click.condition.type) return
        }
      }
      if (options.detail.type === 'popup') {
        let requestId = 'id'
        if (props.options.detail.requestId)
          requestId = props.options.detail.requestId
        router.push({
          name: `${route.name}/:${requestId}`,
          params: {
            ...router.currentRoute.params,
            [requestId]: row.row.id,
          },
        })
        popupForm.value.isShow = true
      }
    }


    const openCell = ($event, row, cell, card) => {
      if (cell?.click) {
        if (cell.click.condition) {
          const condition = cell.click.condition.permissions.includes(
            store.state.user.permission_id
          )
          if (condition !== cell.click.condition.type) return
        }
      }
      if (props.options.detail.type === 'popup' && !cell.noAction) {
        if (cell.routeParam) {
          router.push({
            name:  `${route.name}/${cell.routeName}/:id`,
            params: {
              id: row.id ?? cell.key,
            },
          })
        } else {
          router.push({
            name: `${route.name}/payment/:id`,
            params: {
              id: row.id ?? cell.key,
            },
          })
        }

        popupForm.value.isShow = true
      }
    }

    const closePopupForm = async () => {
      router.push({ name: route.matched.at(-2).name })
      popupForm.value.isShow = false
      clearUnusedParams()
    }

    const changeMonth = async (val) => {
      currentDate.value.date = moment(`${currentDate.value.date}-10`)
        .add(val, 'M')
        .format('YYYY-MM')
      currentDate.value.year = currentDate.value.date.split('-')[0]
      currentDate.value.month = Number(currentDate.value.date.split('-')[1]) - 1
      setTimeout(() => {
        countingDistances()
      }, 0)
      addDayOfMonth()
      await getItems()
    }

    const addItem = () => {
      if (options.detail.type === 'popup') {
        router.push({
          name: `${route.name}/add`,
        })
        popupForm.value.isShow = true
      }
    }
    const changeUrl = (url) => {
      if (options.detail.type === 'popup') {
        router.push({
          name: `${route.name}/${url}`,
          replace: true,
        })
        popupForm.value.isShow = true
        return
      }
      router.push({
        name: url,
      })
      popupForm.value.isShow = true
    }
    const openTable = (result, action, options) => {
      hash.value = result
      if (result && action) {
        localStorage.value.action = action
        initMassActionsPopup(props.options, {
          alias: props.options.options.tableName,
          action,
          popupSubtitle: 'Записи удовлетворяющие условию выбранного действия:',
          successMessage: options.successMessage,
        })
        router.push({
          name: `${route.name}-many`,
        })
      }
      popupForm.value.isShow = true
    }
    const changeUrlPath = (url) => {
      if (options.detail.type === 'popup') {
        router.push({
          name: url,
        })
        popupForm.value.isShow = true
      } 
    }
    const pushUrl = (url) => {
      if (options.detail.type === 'popup') {
        router.push( `${router.currentRoute.path + url}`)
        popupForm.value.isShow = true
      }
    }
    const panelHandler = async (button) => {
      const { type, url, methodOnSelectedMany } = button
      const context = {
        store,
        items: selectedRowsList.value.items,
        idArray: selectedRowsList.value.map((x) => x.row.id),
        tableComp,
        options: props.options,
      }
      if (button.function) button.function(props.options)
      if (type === 'addItem') {
        addItem()
      } else if (type === 'changeUrl') {
        if (selectedRowsList.value.length) {
          if (button.action) localStorage.value.action = button.action
          hash.value = await methodOnSelectedMany(context)
        } else hash.value = 0
        changeUrl(url)
      } else if (type === 'pushUrl') {
        pushUrl(url)
      } else if (type === 'getFilters') {
        axios.post(url, filtersColumns.value)
      } else if (type === 'nextStage') {
        emit('nextStage', {})
      } else if (button.label === 'Обновить') {
        selectedRowsList.value = []
        switchStatus.value = false
        await getItems()
      } else if (type === 'sendPage') {
        let sorts = []
        let searchColumns = []

        paramsQuery.value.sorts.forEach((el) => {
          if (!el.value) {
            return
          } else {
            sorts.push(el)
          }
        })
        paramsQuery.value.searchColumns.forEach((el) => {
          if (!el.value) {
            return
          } else {
            searchColumns.push(el)
          }
        })
        Vue.set(button, 'loading', true)
        const path = await store.dispatch('table/sendPage', {
          url: button.requestUrl,
          content: {
            searchGlobal: paramsQuery.value.searchGlobal,
            filter: filtersColumns.value,
            searchColumns,
            sorts,
            countRows: paramsQuery.value.countRows,
            currentPage: paramsQuery.value.currentPage,
          },
        })
        button.loading = false
        Vue.downloadFile(path.url)
        getItems()
      } else if (type === 'changeComp') {
        emit('changeComp')
      } else if (type === 'selectedItems') {
        const context = {
          store,
          items: lastSelected.value.items,
          idArray: lastSelected.value.items.map((x) => x.row.id),
        }
        await button.method(context)
        const { result, action } = await button.method(context, button)
        if (result && action) {
          openTable(result, action, button)
        }
      } else if (button.customContent) {
        if (button.customContent.component) {
          customContent.value.popup.width = button.customContent.popupWidth
          customContent.value.component = button.customContent.component
          customContent.value.popup.isShow = true
          customContent.value.data = {
            store,
            route,
            router,
            button,
            customContent,
            paramsQuery,
            filtersColumns,
          }
        }
      }
      if (button.refreshTable) {
        selectedRowsList.value = []
        await getItems()
      }
    }

    // COMPUTED PROPERTIES
    const width = computed(() => {
      return window.innerWidth
    })
    const colspanLength = computed(() => {
      return options.options.selecting
        ? options.head.filter((el) => el.isShow).length + 1
        : options.head.filter((el) => el.isShow).length
    })
    const headActions = computed(() => {
      return options.head.find((cell) => cell.type === 'actions')
    })

    // // WATCH
    // watch(
    //   () => searchField,
    //   (newVal) => {
    //     props.options.options.search.function(newVal)
    //   },
    //   () => {
    //   }
    // )

    // HOOKS
    const initialFilter = () => {
      const filter = {}
      filters.value?.fields?.forEach((item) => {
        filter[item.name] = item.value
      })
      return filter
    }

    const addDayOfMonth = () => {
      props.options.head = props.options.head.filter((item) => !item.added)
      const date = new Date(currentDate.value.year, currentDate.value.month, 1)
      const dateNow = new Date()
      let lastLeftIndex = props.options.head.findLastIndex(
        (x) => x.fixed.position === 'left'
      )
      const pushDay = () => {}

      if (props.options.panel.date) {
        while (date.getMonth() === currentDate.value.month) {
          props.options.head.splice(lastLeftIndex + 1, 0, {
            id: uuidv4(),
            title: `${new Date(date).toLocaleDateString('RU', {weekday: 'short'}).toUpperCase()} ${new Date(
              date
            ).getDate()}`,
            align: 'center',
            type: props.options.panel.addedItemsChildrenType
              ? 'object'
              : 'default',
            isShow: true,
            width: '75',
            added: true,
            alias: `p.${new Date(date).getDate()}`,
            value: `${new Date(date).getDate() < 10 ? '0' : ''}${new Date(
              date
            ).getDate()}`,
            currentDate:
              dateNow.getDate() === date.getDate() &&
              dateNow.getMonth() === date.getMonth() &&
              dateNow.getFullYear() === date.getFullYear(),
            weekendDate: date.getDay() === 0 || date.getDay() === 6,
            fixed: {
              value: false,
            },
            search: {
              field: '',
              isShow: true,
            },
            sorts: [
              {
                type: 'string',
                default: '',
                value: '',
                isShow: false,
              },
            ],
          })
          lastLeftIndex += 1
          date.setDate(date.getDate() + 1)
        }
      } else {
        let dateIndex = 1
        while (dateIndex < 32) {
          props.options.head.splice(lastLeftIndex + 1, 0, {
            id: uuidv4(),
            title: `${dateIndex}`,
            align: 'center',
            type: 'default',
            isShow: true,
            width: '75',
            added: true,
            alias: `p.col${dateIndex}`,
            value: `col${dateIndex < 10 ? '0' : ''}${dateIndex}`,
            fixed: {
              value: false,
            },
            search: {
              field: '',
              isShow: true,
            },
            sorts: [
              {
                type: 'string',
                default: '',
                value: '',
                isShow: false,
              },
            ],
          })
          lastLeftIndex += 1
          dateIndex += 1
        }
      }
      nextTick(() => {
        let all = 0
        cells?.value?.forEach((item, index) => {
          all += Number(props?.options?.head[index].width)
        })
        mainTable.value.style.width = `${all}px`
      })
    }

    onMounted(async () => {
      if (props.options.type === 'Fixed')
      {
        addDayOfMonth()
        countingDistances()
      }
      // if (pinnedColumns.value.length) {
      //   pinnedColumns.value.forEach((x) => {
      //     setColumnFixed(x.title)
      //   })
      // }

      hash.value = props.tableComp?.proxy?.hash
      initHeadParams()
      await saveFilter(initialFilter())
      // await getItems()
      watch(
        () => paramsQuery,
        async () => {
          // if (switchStatus) {
          //   redrawRows()
          //   return
          // }
          await getItems()
        },
        { deep: true }
      )

      if (options?.detail?.alias && props.options.options?.tableName) {
        const data = await store.dispatch('table/get', {
          url: 'v1/entity/control_access/table_mass_action',
          data: {
            data: {
              table_name: props.options.options?.tableName,
            },
          },
        })
        const massActions = options.bottomPanel.buttons
        options.bottomPanel.buttons = []
        if (data.code && data.data?.mass_actions?.length) {
          massActions.forEach((item) => {
            if (data.data.mass_actions.includes(item.action)) {
              options.bottomPanel.buttons.push(item)
            }
          })
        }
      }

      const table = document.querySelector(props.options.selector)
      const headerCells = table.querySelectorAll('.v-table-header-row-cell')
      props.options.head.forEach((el, index) => 
        {
          el.isVisible = true
          Vue.set(props.options.head, index, el)
        })
      let acumWidth = 0
      headerCells.forEach((headerEl) => {
        const id = headerEl.id.split('-table-header')[0]
        if (!id) return
        const headCell = options.head.find((head) => head.value === id)
        const { width, x } = headerEl.getBoundingClientRect()
        headerOptions.value.push({
          id,
          headCell,
          width,
          x,
          fixed: headCell?.fixed,
        })
        setTimeout(() => {
          //
          acumWidth = headerEl?.previousElementSibling?.offsetWidth + acumWidth
        }, 0)
      })
      //wrapingRow()
      window.addEventListener('resize', () => watchScroll())
      watchScroll()
      recalcColumnWidth()
      pagination.value = {
        ...options.data,
      }
      const requestId = props.options.detail.requestId || 'id'

      if (!router.currentRoute.params[requestId]) return

      if (
        options.detail &&
        options.detail.type === 'popup' &&
        route.meta.mode
      ) {
        popupForm.value.isShow = true
      }
    })
    onBeforeRouteUpdate( (to, from, next) => {
      next()
      calcShowPopup()
    })
    const styleDate = (row, cell, innerDataCallBack) => {
      if ('conditionValue' in cell) {
        const conditionValue = innerDataCallBack(row, cell.conditionValue)
        return conditionValue ? 'font-style: normal; font-size: 14px' : ''
      }
      return ''
    }

    const iconColor = (value, conditionValue) => {
      if (value === 0) {
        return 'red'
      } else if (value === 1) {
        if (conditionValue) {
          return conditionValue === null ? 'red' : 'black'
        } else {
          return 'green'
        }
      } else if (value === 2) {
        return 'orange'
      }
      return 'blue'
    }
    const iconType = (row, cell, innerDataCallBack) => {
      const value = innerDataCallBack(row, cell.value)

      if (value === 0) {
        return 'mdi-close'
      } else if (value === 1) {
        if ('conditionValue' in cell) {
          const conditionValue = innerDataCallBack(row, cell.conditionValue)
          const dateValue = new Date(conditionValue)
          const formattedDateMax = `${dateValue.getDate()}.${
            dateValue.getMonth() + 1
          }.${dateValue.getFullYear()}`
          return conditionValue
            ? moment(dateValue, 'YYYY-MM-DD').format('DD.MM.YYYY')
            : 'mdi-check'
        } else {
          return 'mdi-check'
        }
      } else if (value === 2) {
        return 'mdi-minus'
      }
      return 'mdi-help'
    }

    const addBackgroundClass = (cell, row, innerDataCallBack) => {
      if ('backgroundValue' in cell) {
        const value = innerDataCallBack(row, cell.backgroundValue)
        return {
          'v-table-body-row-cell--error1': value === 1,
          'v-table-body-row-cell--error2': value === 2,
        }
      }

      return {}
    }

    const checkFieldExist = computed((obj, key) => {
      return key in obj
    })

    const permission = computed(() => store.state.user.permission_id)
    const vertical = computed(() => store.state.user.is_personal_vertical)
    const directions = computed(() => store.state.user.direction_json)
    const availablePanelBtn = computed(() => {
      const checkIncludesPermissions = (el) => {
        if (!el.permissions) return false
        else {
          return el.permissions.includes(permission.value)
        }
      }
      const checkIncludesDirections = (el) => {
        //return el.direction_id.includes(directions.value)
        if (!el.direction_id) return false
        else {
          return !!_.intersection(el.direction_id, directions.value).length
        }
      }
      const checkIncludesVertical = (el) => {
        if (!el.vertical) return false
        else {
          return vertical.value === el.vertical
        }
      }
      const funcCondition = (el) => {
        if (!el.funcCondition) return false
        const conditionContext = {
          store,
          permission,
          vertical,
          directions,
        }
        return el.funcCondition(conditionContext)
      }
      return props.options.panel.buttons.filter((btn) => {
        if (!btn.isShow) return btn
        else {
          return btn.isShow.condition.every((el) => {
            const result =
              el.type === funcCondition(el) ||
              checkIncludesPermissions(el) ||
              checkIncludesVertical(el) ||
              checkIncludesDirections(el)
            return result
          })
          // if ()
        }
      })
    })

    const insertStyle = (row, rowOptions) => {
      let styles = {}
      if (props.options.options.styleRow) {
        props.options.options.styleRow.forEach((el) => {
          const style = el.result[row[el.targetKey]]
          for (let key in style) {
            styles = {
              ...style,
            }
          }
        })
      }
      if (props.options.data.conditionalFormatting) {
        props.options.data.conditionalFormatting
        .conditions.forEach((el) => {
          el.funcCondition({row: row, styles, store})
        });
      }
      return styles
    }

    const clickHandler = async ({ action }) => {
      if (props.tableComp?.proxy?.hash && !!action?.func) {
        const status = await action.func(
          tableContext.value,
          props.tableComp.proxy.localStorage,
          props.tableComp.proxy.hash
        )
        if (status) {
          props.tableComp.proxy.hash = 0
          props.tableComp.proxy.localStorage = {}
          if (props.detail.tabs.at(-1).path.includes('many'))
            props.detail.tabs.pop()
          emit('closePopup', action.to)
          if (props?.tableComp?.proxy?.selectedRowsList) {
            props.tableComp.proxy.selectedRowsList = []
            props.tableComp.proxy.switchStatus = false
          }
          await props.tableComp.proxy.getItems()
        }
      } else {
        if (props?.tableComp?.proxy?.selectedRowsList)
          props.tableComp.proxy.selectedRowsList = []
        selectedRowsList.value = []
        emit('closePopup', action.to)
      }
    }

    const showAction = (action, cell, row) => {
      if (action.funcCondition) {
        const conditionContext = {
          store,
          action,
          cell,
          row,
        }
        return action.funcCondition(conditionContext)
      }
      return true
    }

    const triggerAction = (action, cell, row) => {
      if (action.method) {
        const conditionContext = {
          store,
          action,
          cell,
          row,
          Vue,
          tableComp,
        }
        action.method(conditionContext)
      }
    }

    const downloadFile = (val) => {
      Vue.downloadFile(val)
    }

    const changeHeaders = async () => {
      initHeadParams()
      await getItems()
    }

    return {
      // DATA
      headerOptions,
      filtersCount,
      tablePosition,
      searchField,
      lastSelected,
      options,
      contextmenu,
      pagination,
      filter,
      isMobile,
      proxyOptions,
      panel,
      currentDate,
      selectedRowsList,
      switchStatus,
      header,
      pinnedColumns,
      hash,
      allRowsSelector,
      availibleTitlesForSortIcons,
      // METHODS

      calcPinnedMargin,
      openCell,
      setColumnFixed,
      setColumnVisibility,
      availableActions,
      resetSelected,
      panelAction,
      redrawRows,
      addBackgroundClass,
      iconColor,
      iconType,
      wrapingRow,
      openChildRow,
      checkboxInput,
      saveLastSelected,
      clearField,
      openSort,
      sortRow,
      openContext,
      getWidth,
      setStickyCells,
      getFixedStyle,
      openFilter,
      closeFilter,
      getItems,
      watchScroll,
      handlerContext,
      changeMonth,
      showAction,
      triggerAction,
      countingDistances,
      resetSelected,
      redrawRows,
      // COMPUTED PROPERTIES
      styleDate,
      checkFieldExist,
      // iconType,
      // iconColor,
      width,
      colspanLength,
      headActions,
      loading,
      paramsQuery,
      rowCount,
      isElementXPercentInViewport,
      saveFilter,
      doubleHandler,
      closePopupForm,
      popupForm,
      filtersColumns,
      detail,
      filters,
      addItem,
      panelHandler,
      availablePanelBtn,
      clickHandler,
      switchStatus,
      insertStyle,
      downloadFile,
      contextMenuRef,
      changeHeaders,
      confirmDialog,
      triggerDialogFunction,
      route,
      customContent,
      tableComp,
      tableContext,
      mainTable,
    }
  }
}


export default table
