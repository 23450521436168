var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{style:({
    minWidth: `${_vm.tableContext.config.options?.cellSizes?.width}px`,
    minHeight: `${_vm.tableContext.config.options?.cellSizes?.height}px`,
    verticalAlign: _vm.tableContext.config.type === 'Fixed' ? 'top' : 'middle',
    cursor:
      _vm.cell.added && _vm.tableContext.config.type === 'Fixed' ? _vm._ : 'pointer',
  }),on:{"dblclick":function($event){!_vm.cell.added && _vm.tableContext.config.type === 'Fixed'
      ? _vm.emitClick(_vm.cell)
      : _vm.emitClick(_vm._)}}},[_c('div',{staticClass:"d-flex",style:({ justifyContent: _vm.cell.align })},[(!_vm.cell.rows)?_c('div',[(_vm.tableContext.config.type === 'Fixed')?_c('div',[(_vm.cell.added)?_c('div',{staticClass:"ma-0"},_vm._l((_vm.row.row[_vm.cell.value]),function(entity){return (_vm.tableContext.config.options.customCell)?_c(_vm.tableContext.config.options.customCell,{tag:"component",attrs:{"payload":entity,"cell":_vm.cell},on:{"dblclick":function($event){return _vm.emitClick(entity)}}}):_vm._e()}),1):_c('div',[(_vm.cell.customFixedCell)?_c(_vm.cell.customFixedCell,{tag:"component",attrs:{"payload":_vm.row.row[_vm.cell.value],"cell":_vm.cell}}):_vm._e(),_vm._v(" "+_vm._s(_vm.row.row[_vm.cell.value])+" ")],1)]):_c('div',{style:({
          width: `${_vm.cell.width}px`,
          minWidth: `${_vm.cell.width}px`,
        })},[(!_vm.cell.icons)?_c('div',{staticClass:"row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('p',_vm._g(_vm._b({},'p',attrs,false),on),[_vm._v(_vm._s(_vm.row.row[_vm.cell.value]))])]}}],null,false,597750873)},[_c('span',[_vm._v(_vm._s(_vm.row.row[_vm.cell.value]))])])],1):_c('v-icon',{attrs:{"color":_vm.cell.icons[_vm.row.row[_vm.cell.value]]?.color ?? ''}},[_vm._v(_vm._s(_vm.cell.icons[_vm.row.row[_vm.cell.value]].icon))])],1)]):_vm._e(),(_vm.cell?.icons?.prependIcon)?_c('div',{staticClass:"prepend-icon"},[(_vm.cell?.icons?.prependIcon?.type === 'Bank_Logo')?_c('banksCards',{attrs:{"value":_vm.row.row[_vm.cell?.icons?.prependIcon?.value]}}):_vm._e()],1):_vm._e(),_c('div',_vm._l((_vm.cell.rows),function(field,index){return (_vm.cell.rows)?_c('div',{key:index,class:['row', ...(field.class || []), ...(_vm.cell.class || [])],style:({
          color: !field.color ? 'inherit' : `var(${field.color})`,
          maxWidth: `${_vm.cell.width}px`,
          justifyContent: field.align,
          ...field.style,
        })},[(field.type === 'Text')?[(field.formatting === 'Money')?_c('div',[_vm._v(" "+_vm._s(_vm.moneyFormatter(_vm.row.row[field.value]))+" ")]):(field.formatting === 'Bank_Card')?_c('div',[_vm._v(" "+_vm._s(_vm.bankCardFormatter(_vm.row.row[field.value]))+" ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('p',_vm._g(_vm._b({},'p',attrs,false),on),[_vm._v(_vm._s(_vm.row.row[field.value]))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.row.row[field.value]))])])]:_vm._e(),(field.type === 'CheckBox')?[_c('v-checkbox',{attrs:{"readonly":""},model:{value:(_vm.row.row[field.value]),callback:function ($$v) {_vm.$set(_vm.row.row, field.value, $$v)},expression:"row.row[field.value]"}})]:_vm._e(),(field.type === 'Action')?[_c('v-btn',{attrs:{"disabled":!!field.funcCondition({ ..._vm.tableContext, row: _vm.row }),"x-large":"","icon":"","color":field?.color},on:{"click":function($event){return field.action({ ..._vm.tableContext, row: _vm.row })}}},[(field?.icon?.icon)?_c('v-icon',{attrs:{"color":field?.icon?.color}},[_vm._v(_vm._s(field?.icon?.icon))]):_vm._e()],1)]:_vm._e(),(field.type === 'Tag')?[_c('div',{staticClass:"tag",staticStyle:{"border-radius":"4px"},style:({
              color: _vm.row.row[field.color],
              backgroundColor: `color-mix(in oklab, ${
                _vm.row.row[field.color]
              } 15%, transparent)`,
            })},[_vm._v(" "+_vm._s(_vm.row.row[field.value])+" ")])]:_vm._e(),(field.type === 'Date')?[_vm._v(" "+_vm._s(field.preffix)+" "+_vm._s(_vm.convertData(_vm.row.row[field.value]))+" ")]:_vm._e()],2):_vm._e()}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }