import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import CustomIcons from '@/components/Icons/index'

Vue.use(Vuetify)
export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        topbar: '#FFFFFF',
        text: '#334D6E',
        gray: '#90A0B7',
        navbar: '#FFFFFF',
        background: '#ffffff',
        pageBackground: '#F1F2F6',
        disabled: '#C2CFE0',
        textDefault: '#000000',
        textGray: '#A1A1A1',
        cardBackground: '#FFFFFF',
        red: '#FF5252',
        gradient1: '#fafafa',
        gradient2: '#ededed',
        lightBlue: '#EDF5FD',
        mainBlue: '#4E9EEE',
        activeBlue: '#3E7EBE',
        lightGray: '#F4F4F4',
        tableStroke: '#424549',
        redLightHover: `#FBE4E2`,
        foreground: `#424549`,
        foregroundIcons: '#9D9FA2',
        scrollTrack: '#EBEBEB',
        buttonBorder: '#DBE5EE',
        scrollBarThumb: '#C1C1C1',
      },
      dark: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        topbar: '#252525',
        text: '#FFFFFF',
        navbar: '#252525',
        pageBackground: '#1D1D1D',
        background: '#252525',
        textDefault: '#ffffff',
        cardBackground: '#1E1E1E',
        gradient1: '#1d1d1d',
        gradient2: '#333333',
        lightBlue: '#3B77B3',
        mainBlue: '#000000',
        activeBlue: '#4E9EEE',
        lightGray: '#363636',
        tableStroke: '#777777',
        redLightHover: `#FBE4E2`,
        foreground: `#000000`,
        foregroundIcons: '#000000',
        scrollTrack: '#EBEBEB',
        buttonBorder: '#DBE5EE',
        scrollBarThumb: '#C1C1C1',
      },
      blue: {
        primary: '#07e0f8',
        secondary: '#0bf4de',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        topbar: '#334D6E',
        text: '#FFFFFF',
        navbar: '#30b7cf',
        background: '#39c6c5',
      },
      green: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        topbar: '#FFFFFF',
        text: '#00FF00',
        navbar: '#00FF00',
        background: '#00FF00',
      },
    },
  },
  icons: {
    values: {
      ...CustomIcons,
    },
  },
})
